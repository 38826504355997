.container-dashboard {
  margin-top: 0;
  height: 100%;
}

.dashboard-container__top-search {
  width: 100%;
  height: 100%;
  padding: 12px 50px 12px 55px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 16px;
}

.dashboard-container__top-search::-webkit-input-placeholder {
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 16px;
}

.dashboard-container__top {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
}

.dashboard-container__top-search-box {
  width: 80%;
  position: relative;
}

.dashboard-container__top-search-box img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
}

.dashboard-container__top-btn-box {
  width: 18%;
}

.dashboard-container__top-btn {
  width: 100%;
  height: 100%;
  background: var(--bu-white);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #4db6ac;
  border-radius: 4px;
  color: #0ab3b0;
  font-family: var(--bu-font-regular);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.dashboard-container__top-btn:focus {
  outline: none;
}

.dashboard-container__selection {
  display: flex;
  width: 100%;
  height: 100%;
}

.dashboard-container__selection_btn {
  width: 50%;
  height: 50px;
  box-sizing: border-box;
  border: none;
  background: #ced7da;
  color: #666;
  font-family: var(--bu-font-regular);
  font-size: 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  transition: 0.4s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-container__selection_btn:focus {
  outline: none;
  text-decoration: none;
}

.dashboard-container__selection_btn.active {
  background: #4a4a4a;
  color: var(--bu-white);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
}

.dashboard-container__otherBtn {
  display: flex;
  justify-content: space-between;
}

.dashboard-container__otherBtn-left {
  height: 42px;
  display: flex;
}

.dashboard-container__otherBtn-left-left {
  display: flex;
}

.dashboard-container__otherBtn-right-right {
  display: flex;
  margin-top: 7px;
}

.dashboard-container__otherBtn-left-item {
  border-radius: 4px;
}

.dashboard-container__otherBtn-left-item span {
  display: block;
  height: 40px;
  font-family: var(--bu-font-regular);
  font-size: 16px;
  color: #777777;
}

.dashboard-container__otherBtn-left-item a {
  font-family: var(--bu-font-regular) !important;
  font-size: 16px !important;
  color: #777777 !important;
  height: 40px;
}

.dashboard-container__otherBtn-right {
  display: flex;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.dashboard-container__otherBtn-right-create {
  width: 160px;
  height: 44px;
  box-sizing: border-box;
  cursor: pointer;
  background: #0ab3b0;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--bu-font-regular);
  border: 1px solid #4db6ac;
  border-radius: 4px;
  background-color: #0ab3b0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.dashboard-container__otherBtn-right-create:focus {
  outline: none;
}

.dashboard-container__otherBtn-right-box {
  width: 300px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  background: #fff;
  display: flex;
}

.dashboard-container__otherBtn-right-box-link {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #666666;
  font-family: var(--bu-font-regular);
}

.dashboard-container__otherBtn-right-box-link.center {
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}

.dashboard-container__otherBtn-right-box-link.active {
  background: #f1a525;
  color: var(--bu-white);
}

.statistics__other {
  margin-bottom: 15px;
  margin-top: 5px;
}

.statistics__other-box {
  height: 80px;
  display: flex;
  overflow: hidden;
}

.statistics__other-box-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: var(--bu-control-border-radius);
  border: 1px solid var(--bu-gray-400);
  background-color: var(--bu-white);
}

.statistics__other-box-item:first-child {
  margin-right: 7px;
}

.statistics__other-box-item:last-child {
  margin-left: 7px;
}

.statistics__other-box-item-top {
  height: 41%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: var(--bu-gray-700);
}

.statistics__other-box-item-bottom {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bu-gray-900);
  font-size: 1.7em;
}

.statistics__other-box-item.red > .statistics__other-box-item-bottom {
  color: var(--bu-red-500);
}

.statistics__other-box-item-top_left {
  font-size: 30px;
  color: #777777;
  font-weight: 600;
  font-family: var(--bu-font-regular);
}

.statistics__other-box-item-top_left.green {
  color: #04dab2;
}

.statistics__other-box-item-top_left.red {
  font-size: 24px;
  color: #ff7271;
}

.statistics__other-box-item-top_right {
  font-family: var(--bu-font-regular);
  padding-left: 20px;
  font-size: 12px;
  padding-bottom: 10px;
}

.statistics__other-box-item-top_right.turquoise {
  color: #0ab3b0;
  position: relative;
}

.statistics__other-box-item-top_right.turquoise:after {
  position: absolute;
  content: '';
  left: 5px;
  top: 4px;
  width: 10px;
  height: 6px;
  background: url('../../assets/images/icons/increase_arrow.png');
}

.statistics__other-box-item-top_right.red {
  font-size: 24px;
  color: #f74544;
  position: relative;
}

.statistics__other-box-item-top_right.red:after {
  position: absolute;
  content: '';
  left: 5px;
  top: 4px;
  width: 10px;
  height: 6px;
  background: url('../../assets/images/icons/decrease_arrow.png');
}

.statistics__graphics-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* .statistics__graphics-top-left,
.statistics__graphics-top-right {
  width: 49%;
  min-height: 380px;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
} */
.statistics__graphics-bottom-fullWidth {
  width: 100%;
  min-height: 380px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  margin-top: 22px;
}

.statistics__table {
  width: 100%;
  height: auto;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  margin-top: 22px;
}

.statistics__table-header {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.statistics__table-header_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 13px 30px;
}

.statistics__table-header_info-title {
  color: #4085c5;
  font-size: 18px;
  font-family: var(--bu-font-regular);
  margin-right: 18px;
}

.statistics__table-header_info-item,
.statistics__table-header_info-item {
  padding: 3px 16px;
  height: 24px;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  margin-right: 8px;
}

.statistics__table-header_info-item.orange {
  background: #ee724e;
}

.statistics__table-header_info-item.turquoise {
  background: #0ab3b0;
}

.statistics__table-header-list {
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 40px;
  background: #f4f7f8;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}

.statistics__table-header-item {
  display: flex;
  height: 100%;
  align-items: center;
  color: #777777;
  font-family: var(--bu-font-regular);
  font-size: 13px;
  font-weight: bold;
}

.statistics__table-header-item.two {
  width: 27%;
}

.statistics__table-header-item.one {
  width: 19%;
}

.statistics__table-body-list {
  display: flex;
  padding: 0 15px;
  list-style: none;
  position: relative;
  margin: 0;
}

.statistics__table-body-list:nth-child(2n) {
  background: #f9f9f9;
}

.statistics__table-body-list::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  left: 0;
  top: -1px;
  background: #dddddd;
}

.statistics__table-body-item {
  margin-top: 14px;
  margin-bottom: 10px;
  padding-right: 10px;
}

.statistics__table-body-item.one {
  width: 19%;
}

.statistics__table-body-item.two {
  width: 27%;
}

.dashboard-container__table {
  /* margin-top: 20px; */
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.dashboard-container__table-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dashboard-container__table-list-item {
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.dashboard-container__table-list-item:last-child {
  border-bottom: none;
}

.dashboard-container__table-list-item-left {
  padding-left: 50px;
  position: relative;
}

.dashboard-container__table-list-item-left img {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dashboard_name-box {
  display: flex;
  flex-direction: column;
}

.dashboard_name-box-title {
  font-family: var(--bu-font-regular);
  font-weight: bold;
  color: #4a4a4a;
  font-size: 18px;
}

.dashboard_name-box-subtitle {
  font-size: 14px;
  font-family: var(--bu-font-regular);
  color: #777777;
}

.dashboard-container__table-list-item-right {
  display: flex;
}

.dashboard_info-box {
  height: 60px;
  min-width: 120px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: var(--bu-white);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard_info-box_top {
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 18px;
  font-weight: 600;
}

.dashboard_info-box_top.orange {
  color: #ee724e;
}

.dashboard_info-box_bottom {
  color: #777777;
  font-family: var(--bu-font-regular);
  font-size: 13px;
  line-height: 18px;
}

.close-icon {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
  top: -35px;
  display: block;
  cursor: pointer;
}

.close-icon:after {
  position: absolute;
  left: 10px;
  top: -2px;
  width: 2px;
  height: 26px;
  content: '';
  background: #9b9b9b;
  transform: rotate(45deg);
}

.close-icon:before {
  position: absolute;
  left: 10px;
  top: -2px;
  width: 2px;
  height: 26px;
  content: '';
  background: #9b9b9b;
  transform: rotate(135deg);
}

.dashboard-container__top-search-box-parent {
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}

.dashboard-container__allreports .dashboard-container__top-search-box-parent {
  width: 100%;
}

.dashboard-container__allreports
  .dashboard-container__top-search-box-parent
  .dashboard-container__top-search-box {
  width: 98%;
}

.dashboard-container__allreports
  .dashboard-container__top-search-box-parent
  .dashboard-container__top-btn-box {
  display: none;
}

.dashboard-container__allreports {
  display: flex;
}

.dashboard-message-noResults {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--bu-font-regular);
}

.statistics__graphics-bottom--fullWidth {
  width: 100%;
  height: 420px;
  margin-top: 15px;
  border: 1px solid #dddddd;
  border-radius: 2px;
}

.dimmable:not(body) {
  z-index: 987;
}

.ui.dimmer {
  background-color: rgba(34, 63, 83, 0.5);
}

.ui.dimmer .ui.loader:before {
  border-color: rgba(103, 100, 100, 0.15);
}

.ui.dimmer .ui.loader:after {
  border-color: #8a8989 transparent transparent;
}

.message-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  font-family: var(--bu-font-regular);
}

.message-container .message-container-btn {
  margin: 10px 0;
}

.modal--overflow {
  overflow-y: auto !important;
  height: 100%;
  overflow-x: hidden;
}

.modal--overflow .container__callTranscript {
  max-height: calc(100vh - 79px);
  overflow-y: auto;
}

.modal--overflow .callTranscript__table {
  margin-top: 380px;
}

.modal--overflow .callTranscript__table-transcription {
  margin-top: 370px;
}

/* новые стили  */
.statistics__graphics-top .container {
  width: auto;
  padding: 0;
}

.statistics__graphics-container {
  display: flex;
  flex-direction: column;
}

.graphics-container-block.one {
  overflow: hidden;
}

.graphics-container-block.two {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.graphics-container-block.two .graphics-container-block-box {
  width: 49%;
  overflow: hidden;
}

.graphics-container-block {
  margin-bottom: 20px;
}

.graphics-main-box {
  width: 100%;
  height: 100%;
}

.graphics-main-box-content .graphics-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  position: relative;
}

.graphics-content-header {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
}

.graphics-container-block-box .ui.dropdown {
  padding: 0;
}

.graphics-container-block-box .centered.middle.aligned.row {
  padding-bottom: 0;
}

.graphics-container-block-box .stretched.one.column.row {
  padding-top: 0;
}

.graphics-container-block-box .dropdown {
  padding: 0;
}

.graphics-container-block-box .center.aligned.eight.wide.column h4 {
  font-family: var(--bu-font-regular);
  font-size: 18px;
  color: #4a4a4a;
}

.graphics-container-block-box .ui.segment {
  height: 100%;
}

.allDeals-container.noMargin {
  margin: 0;
  position: relative;
  height: 100%;
}

.allDeals-headerFixed {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
}

.allDeals-table {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  background: #fff;
}

.allDeals-table.noMargin {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  height: calc(100% - 107px);
  bottom: 0;
  overflow-y: auto;
}

.allDeals-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 18px;
  font-weight: 600;
  padding: 14px 31px;
  border-bottom: 1px solid #dddddd;
  background: #fff;
}

.allDeals-table-header-row {
  padding: 11px 31px;
  display: flex;
  background: #f4f7f8;
  color: #777;
  font-family: var(--bu-font-regular);
  font-size: 13px;
  font-weight: 800;
  text-transform: uppercase;
}

.allDeals-table-header-column {
  display: flex;
  align-content: space-between;
}

.allDeals-table-header-column.one {
  width: 18%;
}

@media all and (max-width: 1200px) {
  .allDeals-table-header-column.one {
    width: 18%;
    position: relative;
    padding-right: 20px;
  }
}

.allDeals-table-header-column.oneWidth {
  width: 18%;
}

.allDeals-table-header-column.two {
  width: 16%;
}

.allDeals-table-header-column.twoWidth {
  width: 23%;
}

.allDeals-table-body-row {
  padding: 16px 31px;
  display: flex;
}

.allDeals-table-body-row-closed {
  opacity: 0.6;
}

.allDeals-table-body-column.one {
  width: 18%;
  padding-right: 10px;
  overflow: hidden;
}

.allDeals-table-body-column.oneWidth {
  width: 18%;
}

.allDeals-table-body-column.two {
  width: 16%;
}

.allDeals-table-body-column.twoWidth {
  padding-right: 10px;
  width: 23%;
}

.allDeals-table-body-column .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.allDeals-table-body-row {
  border-bottom: 1px solid #dddddd;
}

.allDeals-table-body-row.active {
  background: #fffff5;
}

.allDeals-table-body-column-name {
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 3px;
  cursor: pointer;
  hyphens: auto;
  padding-right: 0px;
  transition: 0.4s;
}

.allDeals-table-body-column-name.blue {
  color: #0053cc;
  font-size: 14px;
}

.allDeals-table-body-column-other {
  color: #444444;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  font-weight: 100;
  /* word-break: break-word; */
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  width: -webkit-fill-available;
}

@media (max-width: 1200px) {
  .allDeals-table-body-column-other {
    font-size: 11px;
  }
}

.allDeals-table-body-column-other_bold {
  display: block;
  margin-top: 8px;
  color: #000;
  font-family: var(--bu-font-medium);
  font-size: 15px;
}

.allDeals-table-body-column-name:hover {
  color: #0ab3b0;
}

.allDeals-table-body-column-name-text {
  color: #9b9b9b;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  font-weight: 800;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.allDeals-table-body-column-name-text--span {
  font-family: var(--bu-font-regular);
  margin-left: 5px;
}

.allDeals-table-body-column-money {
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 16px;
  font-weight: 800;
  display: inline-block;
  float: right;
}

.allDeals-table-body-column-money.orange {
  color: #f1a525;
}

.allDeals-table-body-column-money-text {
  color: #777;
  font-family: var(--bu-font-regular);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 3px;
}

.allDeals-table-body-otherText {
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 3px;
}

.allDeals-table-body-percent-container:hover .allDeals-table-body-percent {
  color: #0053cc;
}

.allDeals-table-body-percent-container:hover .allDeals-table-body-percent.red {
  color: #f74544;
}

.allDeals-table-body-percent {
  color: #4a4a4a;
  cursor: pointer;
  font-family: var(--bu-font-regular);
  font-size: 13px;
  font-weight: 800;
  display: inline-block;
}

.allDeals-table-body-percent.red {
  color: #f74544;
}

.allDeals-table-body-percent.coral {
  color: #ee724e;
}

.allDeals-table-body-percent.green {
  color: #0ab3b0;
}

.allDeals-table-body-indicator {
  border-radius: 12px;
  color: #fff;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  padding: 3px 17px;
  position: relative;
  cursor: pointer;
}

.allDeals-indicator-popup-window {
  min-width: 300px;
  max-height: 280px;
  padding-top: 5px;
  overflow-y: auto;
}

.allDeals-indicator-popup-window__item.red {
  background: #fce3dc;
  border-radius: 15px;
  padding: 4px 10px;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  display: inline-block;
  margin-bottom: 7px;
  margin-right: 7px;
  position: relative;
  border: 1px solid #ee724e;
}

.allDeals-indicator-popup-window__item.green {
  background: #cef0ef;
  border-radius: 15px;
  padding: 4px 10px;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  display: inline-block;
  margin-bottom: 7px;
  margin-right: 7px;
  position: relative;
  border: 1px solid #47ccca;
}

.allDeals-indicator-popup-window__item.green.active {
  border: 1px solid #0ab3b0;
}

.allDeals-indicator-popup-window__item.green.active:after {
  position: absolute;
  content: '';
  right: -5px;
  top: -4px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #fabb15;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.allDeals-indicator-popup-window__item.red.active {
  border: 1px solid #ee724e;
}

.allDeals-indicator-popup-window__item.red.active:after {
  position: absolute;
  content: '';
  right: -5px;
  top: -4px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #fabb15;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.allDeals-table-body-indicator:hover .allDeals-indicator-popup-window {
  display: block;
}

.allDeals-table-body-indicator.red {
  background: #ee724e;
  position: relative;
}

.allDeals-table-body-indicator.green {
  background: #0ab3b0;
  position: relative;
}

.allDeals-table-body-indicator.red.active:after {
  position: absolute;
  content: '';
  right: -5px;
  top: -4px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #fabb15;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.allDeals-table-body-indicator.green.active:after {
  position: absolute;
  content: '';
  right: -5px;
  top: -4px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #fabb15;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.allDeals-table-noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ee724e;
  font-family: var(--bu-font-regular);
  font-size: 24px;
  padding: 20px;
}

.deal-body-table--header-btn {
  margin-left: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.deal-body-table--header-btn i {
  font-size: 0px;
  display: block;
  width: 8px;
  height: 8px;
  color: #ccc;
  margin-right: 0;
}

.deal-body-table--header-btn .caret.up {
  transform: rotate(180deg);
  margin-bottom: 4px;
  margin-top: -2px;
}

.deal-body-table--header-btn i.active {
  color: #0ab3b0;
}

.header-icon--rotate {
  transform: rotate(180deg);
  margin: -1px 0 4px 0;
  margin-bottom: 3px !important;
}

.deal-header-sort-box {
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: #f0eff5;
  cursor: pointer;
  overflow: hidden;
}

.deal-header-sort-box-link {
  position: relative;
  width: 30px;
  height: 30px;
  display: block;
}

.deal-header-sort-box-link-img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.deal-header-control-box {
  display: flex;
  position: relative;
}

.deal-header-control-box-link {
  position: relative;
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 4px;
  background-color: #f0eff5;
  cursor: pointer;
  overflow: hidden;
  margin-left: 6px;
}

.allDeals-create-report {
  display: flex;
  justify-content: center;
  margin-right: 6px;
  margin-bottom: 10px;
}

.allDeals-create-report-input {
  height: 28px;
  padding: 4px 15px;
  width: 155px;
  border: 1px solid #327cff;
  border-radius: 20px 0 0 20px;
}

.allDeals-create-report-btn {
  height: 28px;
  padding: 4px 15px;
  background: #327cff;
  color: #fff;
  border-radius: 0 20px 20px 0;
  transition: 0.4s;
  box-sizing: border-box;
  border: 1px solid #327cff;
  margin-left: -1px;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
}

.allDeals-create-report-input-span {
  display: inline-block;
  padding: 5px;
  position: absolute;
  left: -140px;
  top: 38px;
  background: #ee724e;
  color: #fff;
  border-radius: 5px;
}

.allDeals-create-report-input-span:after {
  position: absolute;
  content: '';
  width: 14px;
  height: 14px;
  background: #ee724e;
  transform: rotate(135deg);
  top: -5px;
  left: 70px;
}

.allDeals-create-report-btn:hover {
  color: var(--bu-white);
}

.allDeals-list-items {
  border: 2px solid #dddddd;
  border-radius: 4px;
  background-color: var(--bu-white);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.allDeals-list-items-box {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ddd;
}

.allDeals-list-items-box.active {
  background: #fffff5;
}

.allDeals-list-items-box.active .allDeals-list-items-box-item-showIcon-link {
  border-color: #0ab3b0;
}

.allDeals-list-items-box.active
  .minus.allDeals-list-items-box-item-showIcon-link__ico {
  color: #0ab3b0;
}

.allDeals-list-items-box-item {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.allDeals-list-items-box-item-left {
  display: flex;
  max-width: 400px;
  margin-right: 20px;
}

.allDeals-list-items-box-item-showIcon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.allDeals-list-items-box-item-showIcon-link {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  position: relative;
  cursor: pointer;
  transition: 0.4s;
}

.allDeals-list-items-box-item-showIcon-link:hover {
  border-color: #0ab3b0;
}

.allDeals-list-items-box-item-showIcon-link:hover
  .allDeals-list-items-box-item-showIcon-link__ico {
  color: #0ab3b0 !important;
}

.allDeals-list-items-box-item-showIcon-link__ico {
  position: absolute;
  left: 51%;
  top: 47%;
  transform: translate(-47%, -51%);
  transition: 0.2s;
}

.allDeals-list-items-box-item-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.allDeals-list-items-box-item-name img {
  margin-right: 16px;
  width: 40px;
  height: 40px;
}

.allDeals-list-items-box-item-name-block {
  display: flex;
  flex-direction: column;
}

.allDeals-list-items-box-item-name-block-main {
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 18px;
  font-weight: bold;
}

.allDeals-list-items-box-item-name-block-secondary {
  color: #777777;
  font-family: var(--bu-font-regular);
  font-size: 14px;
}

.allDeals-list-items-box-item-info {
  display: flex;
  flex-direction: row;
}

.allDeals-list-items-box-item-info-item {
  display: flex;
  min-width: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: var(--bu-white);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  margin-right: 10px;
}

.allDeals-list-items-box-item-btn {
  display: flex;
  align-items: center;
}

.allDeals-list-items-box-item-btn-link {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f0eff5;
  position: relative;
  margin-left: 6px;
  transition: 0.4s;
  cursor: pointer;
}

.allDeals-list-items-box-item-btn-link:hover {
  background: #0ab3b033;
}

.allDeals-list-items-box-item-btn-link:first-child {
  margin-left: 0;
}

.allDeals-list-items-box-item-btn-link img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.allDeals-list-items-box-item-info-item-main {
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 18px;
  font-weight: 600;
}

.allDeals-list-items-box-item-info-item-main.orange {
  color: #ee724e;
}

.allDeals-list-items-box-item-info-item-secondary {
  color: #777777;
  font-family: var(--bu-font-regular);
  font-size: 13px;
  margin-top: 4px;
}

.allDeals-list-items-box-item {
  border-bottom: 1px solid #dddddd;
}

.allDeals-table-header-row.active {
  border-bottom: 1px solid #f1a525;
}

.allDeals-container.active {
  margin-top: 0;
}

.allDeals-container.active .allDeals-table {
  margin-bottom: 0;
}

.allDeals-container.active .allDeals-table-header-row {
  border-bottom: 1px solid #f1a525;
}

.btn-active-filter-close {
  width: 12px;
  height: 12px;
  display: inline-block;
  position: relative;
  margin-left: 10px;
}

.btn-active-filter-close:after {
  width: 1px;
  height: 14px;
  display: inline-block;
  content: '';
  background: #666;
  position: absolute;
  transform: rotate(45deg);
  top: 0;
  left: 6px;
}

.btn-active-filter-close:before {
  width: 1px;
  height: 14px;
  display: inline-block;
  content: '';
  background: #666;
  position: absolute;
  transform: rotate(135deg);
  top: 0;
  left: 6px;
}

.deals-filter-btn + .deals-filter-btn {
  margin-left: 10px !important;
}

.deals-filter-btn {
  align-content: center;
  padding: 0px 13px !important;
  height: 40px;
  margin: 0 !important;
  background: #fff !important;
  border: 1px solid var(--bu-gray-300) !important;
  border-radius: 2px !important;
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2) !important; */
  color: #777 !important;
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 600;
  font-family: var(--bu-font-regular) !important;
}

.deals-filter-btn.active {
  background: #c9eaff !important;
  border: 1px solid #6aabeb !important;
}

.deals-filter-btn.active:hover {
  background: #c9eaff !important;
}

.deals-filter-btn i {
  margin-right: 0px !important;
  margin-left: 3px !important;
}

.deals-filter-btn:hover {
  background: rgba(0, 0, 0, 0.03) !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.deals-filter-container {
  background: #fff;
  display: none;
  height: auto;
  margin: 20px 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.deals-filter-container.open {
  display: block;
}

.deals-filter-container--controllers {
  /* display: flex; */
  padding: 10px 20px;
  /* flex-direction: row-reverse; */
  /* height: 60px;	 */
  background-color: #f4f7f8;
  /* box-shadow: 0 0 6px 0 rgba(0,0,0,0.2); */
}

.deals-filter-container-items {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  margin-bottom: -1px;
}

.deals-filter-container-item:nth-child(-n + 5) {
  padding-top: 10px;
}

.deals-filter-container-item {
  width: 100%;
  height: 320px;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.deals-filter-controll {
  margin-right: 8px;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  padding: 0 18px;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s;
}

.deals-filter-controll:focus {
  outline: none;
}

.deals-filter-controll.apply {
  background: #0ab3b0;
  color: #fff;
}

.deals-filter-controll.apply:hover {
  background: #0ad2ce;
}

.deals-filter-controll.clear {
  background: #fff;
  color: #666666;
}

.deals-filter-controll.clear:hover {
  background: #e0e0e0;
}

.collapse-btn-table {
  position: absolute;
  left: 40px;
  top: 14px;
  padding: 5px 10px;
  border: 1px solid #ee724e;
  border-radius: 4px;
  transition: 0.4s;
  color: #ee724e;
  font-family: var(--bu-font-regular);
}

.collapse-btn-table:focus {
  outline: none;
  text-decoration: none;
  color: #ee724e;
}

.collapse-btn-table:hover {
  border-color: #327cff;
  color: #327cff;
}

.form-search-label__btn-box {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.form-search-label__btn {
  padding: 5px 10px;
  border: 1px solid #9b9b9b;
  border-radius: 15px;
  font-family: var(--bu-font-regular);
  color: #666;
  cursor: pointer;
}

.form-search-label__btn.active {
  color: #fff;
  border: 1px solid #327cff;
  background: #327cff;
}

.banner {
  display: flex;
  justify-content: center;
  padding: 15px 30px;
  background: #e6e60c;
  font-family: var(--bu-font-medium);
  font-size: 18px;
  font-weight: 800;
  color: #4a4a4a;
  border-radius: 4px;
  text-align: center;
}

.Not_Available {
  font-family: var(--bu-font-regular);
  font-size: 15px;
  font-weight: 800;
  color: #999999;
}

.ui.bottom.right.popup:before {
  right: 3em;
}
